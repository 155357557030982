export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'

// warehouse info service
export const tracingApi = '/warehouse-info-tracing/report'
// warehouse space status
export const warehouseSpaceStatus = '/warehouse-space-status/report'

// warehouse info stock of product
export const getStockProduct = '/warehouse-info/stock-product'
export const reportHeadingList = '/master-warehouse-report-heading/detail'

// warehouse Delivery Service admin panel
export const getDeliveryReport = '/delivery-schedule/report'
