<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <!-- <h4 class="card-title">{{ $t('warehouseInfoService.selectFromTheList') }}</h4> -->
          <h4 class="card-title">{{ $t('warehouseInfoService.warehouseSpaceStatus') }}</h4>
      </template>
      <template v-slot:body>
         <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="4"  v-if="!authUser.warehouse_id">
                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="2"
                      label-cols-md="4"
                      :label="$t('org_pro_division.division')"
                      label-for="division_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{ $t('org_pro_division.division')}}
                    </template>
                      <v-select name="division_id"
                        v-model="search.division_id"
                        label="text"
                        :options= divisionList
                        :reduce="item => item.value"
                        :placeholder="$t('globalTrans.select')"
                        />
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                 </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4"  v-if="!authUser.warehouse_id">
              <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
                  <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label-cols-md="4"
                  :label="$t('warehouse_config.region')"
                  label-for="region_id"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_config.region')}}
                    </template>
                  <v-select name="region_id"
                  v-model="search.region_id"
                  label="text"
                  :options= regionList
                  :reduce="item => item.value"
                  :placeholder="$t('globalTrans.select')"
                  />
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4"  v-if="!authUser.warehouse_id">
               <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                  <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label-cols-md="4"
                  :label="$t('warehouse_report.district')"
                  label-for="district_id"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{ $t('warehouse_report.district')}}
                    </template>
                  <v-select name="district_id"
                    v-model="search.district_id"
                    label="text"
                    :options= regionDistrictLists
                    :reduce="item => item.value"
                    :placeholder="$t('globalTrans.select')"
                    />
                  <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4"  v-if="!authUser.warehouse_id">
               <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="2"
                label-cols-md="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                      {{ $t('warehouse_report.upazilla')}}
                    </template>
                <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
                 </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4"  v-if="!authUser.warehouse_id">
               <ValidationProvider name="Union" vid="union_id" rules="">
                <b-form-group
                class="row"
                label-cols-sm="2"
                label-cols-md="4"
                :label="$t('globalTrans.union')"
                label-for="union_id"
                slot-scope="{ valid, errors }"
                >
                <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
                <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
                 </ValidationProvider>
            </b-col>
              <b-col xs="12" sm="12" md="6" lg="4">
                <ValidationProvider name="Warehouse" vid="warehouse_id" rules="required|min_value:1">
                <b-form-group
                class="row"
                label-cols-sm="2"
                label-cols-md="4"
                label-for="commodity_group_id"
                :label="$t('warehouse_report.warehouse_name')"
                 slot-scope="{ valid, errors }"
                >
                 <template v-slot:label>
                      {{ $t('warehouse_report.warehouse_name')}}
                    </template>
                <v-select name="warehouse_id"
                v-model="search.warehouse_id"
                label="text"
                :options= warehouseList
                v-if="!authUser.warehouse_id"
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
                <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              />
                 <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="authUser.warehouse_id">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
             <b-col v-if="!authUser.warehouse_id">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
        </b-row>
        </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template>
            <b-row>
            <b-col lg="12">
                <b-overlay class="border shadow p-2" :show="loadingState">
                <h5 class="text-center">{{ $t('warehouseInfoService.warehouseSpaceStatus')}}</h5>
                <apexchart class="chart-1 text-center px-5" id="vuechart-example" type="bar" :options="dataOption" :series="series"></apexchart>
                </b-overlay>
            </b-col>
            </b-row>
        </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { warehouseSpaceStatus } from '../../api/routes'
import apexchart from 'vue-apexcharts'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'
Vue.use(apexchart)

export default {
  mixins: [ModalBaseMasterList],
  components: {
      apexchart,
      ValidationObserver,
      ValidationProvider
  },
  data () {
    return {
      series: [{
        name: 'Total',
        data: [45, 70, 49, 60]
      }],
      showData: false,
      showHeading: false,
      division_id: 0,
      region_id: 0,
      search: {
        org_id: 13,
        division_id: null,
        region_id: null,
        district_id: null,
        upazilla_id: null,
        union_id: null,
        warehouse_id: null
      },
      testId: 0,
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      rows: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl
    }
  },
  mounted () {
      if (this.isWareHouseUser) {
        this.search.division_id = this.authUser.office_detail.division_id
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.regionDistrictLists = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
         this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
    }
  },
  computed: {
     authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    dataOption () {
        return {
            chart: {
                id: 'vuechart-example',
                type: 'bar'
            },
            plotOptions: {
              bar: {
                barHeight: '100%',
                columnWidth: '30%',
                distributed: true,
                horizontal: false,
                dataLabels: {
                  position: 'bottom'
                }
              }
            },
            labels: [
                this.$t('warehouseInfoService.totalSpace'),
                this.$t('warehouseInfoService.actualSpace'),
                this.$t('warehouseInfoService.filledSpace'),
                this.$t('warehouseInfoService.freeSpace')
                ],
            colors: ['#666666', '#3333cc', '#ff0000', '#009900']
        }
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    regionList: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
            this.regionDistrictLists = this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
    this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    }
  },
  created () {
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    paginationDataLoad () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        name_bn: ''
      }
    },
    searchData () {
        if (this.search.warehouse_id !== 0) {
            this.loadData()
            this.showData = true
            this.showHeading = true
        }
    },
    loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const params = Object.assign({}, this.search, {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
        })
        RestApi.getData(warehouseServiceBaseUrl, warehouseSpaceStatus, params).then(response => {
            this.series[0].data = []
            var array = []
            if (response.success) {
                array.push(response.data[0].total_capacity)
                array.push(response.data[0].actual_capacity)
                array.push(response.data[0].filled_space)
                array.push(response.data[0].free_space)
            }
            this.series.splice(0, 1, {
                name: 'Total',
                data: array
            })
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }, error => {
            if (error) {
              //
            }
            this.$store.commit('mutateCommonProperties', { loading: false, listReload: false })
        })
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(search => search.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(search => search.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(search => search.value === obj.district_id).text }
                    }
                })
            }
        })
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }
      return list
    }
  }
}
</script>
